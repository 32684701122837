import React, { useEffect, useState } from "react"
import { navigate, Link } from "gatsby"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import "yup-phone"
import { ApplyProvider, useApplyContext } from "../apply/context"
import StepHeader from "../apply/step-header"
import FormikField from "../apply/formik-field"
import USSTATES from "../apply/us-states"
import AppLoadingOverlay from "../apply/app-loading-overlay"
import Modal from "../apply/modal"
import AppTermsModal from "../apply/app-terms-modal"
import { REST } from "../../components/apply/api-calls-map"

const MakePayment = ({ guarantor, paymentId }) => {
  const {
    stepLoading,
    setShowTermsModal,
    setTermsModalContent,
    makePayment,
    makePublicPayment,
    makePlaidPayment,
    getPaymentLink,
    getPaymentDetails,
    setAppLoading,
    linkManually,
    setLinkManually,
    linkBankLoading,
    setLinkBankLoading,
    setAppLoadingStatus,
    API_MAP,
  } = useApplyContext()

  const [existingBankAccount, setExistingBankAccount] = useState(null)

  const [existingBankAccounts, setExistingBankAccounts] = useState(null)

  const [addBankAccount, setAddBankAccount] = useState(false)

  const [initialFormResults, setInitialFormResults] = useState(null)

  const RepaymentInfoSchema = Yup.object().shape({
    amount: Yup.number()
      .moreThan(0, "Amount required.")
      .required("Amount required."),
    firstName: Yup.string().when("useExistingBankAccount", {
      is: val => val === "false" || !existingBankAccount?.existingBankAccount,
      then: schema => schema.required("First Name required."),
    }),
    lastName: Yup.string().when("useExistingBankAccount", {
      is: val => val === "false" || !existingBankAccount?.existingBankAccount,
      then: schema => schema.required("Last Name required."),
    }),
    routingNumber: Yup.string()
      .trim()
      .length(9, "Routing Number must have 9 digits")
      .matches(/^\d+$/, "Routing Number must be a number")
      .when("useExistingBankAccount", {
        is: val => val === "false" || !existingBankAccount?.existingBankAccount,
        then: schema => schema.required("Routing Number required."),
      }),
    bankName: Yup.string().when("useExistingBankAccount", {
      is: val => val === "false" || !existingBankAccount?.existingBankAccount,
      then: schema => schema.required("Bank Name required."),
    }),
    accountNumber: Yup.string()
      .trim()
      .matches(/^\d+$/, "Account Number must be a number")
      .when("useExistingBankAccount", {
        is: val => val === "false" || !existingBankAccount?.existingBankAccount,
        then: schema => schema.required("Account Number required."),
      }),
    confirmAccountNumber: Yup.string()
      .trim()
      .matches(/^\d+$/, "Account Number must be a number")
      .oneOf([Yup.ref("accountNumber"), null], "Account Number don't match!")
      .when("useExistingBankAccount", {
        is: val => val === "false" || !existingBankAccount?.existingBankAccount,
        then: schema => schema.required("Confirm Account Number required."),
      }),
    accountType: Yup.string().when("useExistingBankAccount", {
      is: val => val === "false" || !existingBankAccount?.existingBankAccount,
      then: schema => schema.required("Account Type required."),
    }),
    addressLine1: Yup.string().when("useExistingBankAccount", {
      is: val => val === "false" || !existingBankAccount?.existingBankAccount,
      then: schema => schema.required("Address Line 1 required."),
    }),
    city: Yup.string().when("useExistingBankAccount", {
      is: val => val === "false" || !existingBankAccount?.existingBankAccount,
      then: schema => schema.required("City required."),
    }),
    state: Yup.string().when("useExistingBankAccount", {
      is: val => val === "false" || !existingBankAccount?.existingBankAccount,
      then: schema => schema.required("State required."),
    }),
    // .test("state", "State must be Arizona", val => val && val == "AZ"),
    postalCode: Yup.string().when("useExistingBankAccount", {
      is: val => val === "false" || !existingBankAccount?.existingBankAccount,
      then: schema => schema.required("Zip Code required."),
    }),
  })

  const RepaymentInfoExistingAccountsSchema = Yup.object().shape({
    amount: Yup.number().test({
      name: "check-value",
      test(value, ctx) {
        const selectedAccount = existingBankAccounts.filter(
          account => account.id === parseInt(ctx.parent?.bankAccountId)
        )

        if (
          selectedAccount &&
          selectedAccount?.length &&
          selectedAccount[0]?.availableBalance < value
        ) {
          return ctx.createError({
            message:
              "The account balance is not sufficient to make this payment. Please, adjust the payment amount or choose a different bank account.",
          })
        }

        if (!value) {
          return ctx.createError({ message: "Amount required." })
        }

        return true
      },
    }),
    bankAccountId: Yup.string().nullable().required("Account required."),
  })

  const InitialFormSchema = Yup.object().shape({
    useExistingBankAccount: Yup.string().required("Required."),
    amount: Yup.number()
      .moreThan(0, "Amount required.")
      .required("Amount required."),
    achConsent: Yup.bool().oneOf(
      [true],
      "Please, check the box to confirm you’ve read and agree to the terms above."
    ),
    consent: Yup.bool().when("useExistingBankAccount", {
      is: val => val === "false" || !existingBankAccount?.existingBankAccount,
      then: schema =>
        schema.oneOf(
          [true],
          "Please, check the box to confirm you’ve read and agree to the terms above."
        ),
    }),
  })

  const checkPaymentLink = async () => {
    const [res, status] = await getPaymentLink(paymentId)

    if (res && status === 200 && !res.success) {
      Modal.mixin({
        customClass: {
          confirmButton: "btn btn-dark btn-xl fw-600 w-40",
          cancelButton: "btn btn-danger btn-xl fw-600 w-40",
        },
        buttonsStyling: false,
        backdrop: "#f1f1f1",
      })
        .fire({
          title: "<strong style='text-transform: uppercase'>Hmm...</strong>",
          text: res.message || "Payment successfully completed.",
          confirmButtonText: "Ok",
        })
        .then(result => {
          if (result.isConfirmed) {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
            navigate("/")
          }
        })
    }

    if (res && status === 200 && res.success) {
      setExistingBankAccount(res.data)
    }
  }

  const fetchPaymentDetails = async () => {
    const [res, status] = await getPaymentDetails()

    if (res && status === 200 && !res.success) {
      Modal.mixin({
        customClass: {
          confirmButton: "btn btn-dark btn-xl fw-600 w-40",
          cancelButton: "btn btn-danger btn-xl fw-600 w-40",
        },
        buttonsStyling: false,
        backdrop: "#f1f1f1",
      })
        .fire({
          title: "<strong style='text-transform: uppercase'>Hmm...</strong>",
          text: res.message || "Payment successfully completed.",
          confirmButtonText: "Ok",
        })
        .then(result => {
          if (result.isConfirmed) {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
            navigate("/")
          }
        })
    }

    if (res && status === 200 && res.success) {
      setExistingBankAccount(res.data)
    }
  }

  const showBankLinkServicesAgreementInfo = () => {
    setShowTermsModal(true)
    setTermsModalContent({
      hidePrint: true,
      title: "Bank Link Services Agreement",
      lastUpdate: null,
      text: (
        <p className="lead-2">
          The Bank Link Services Agreement ("Agreement") enables Nextate Credit
          LLC, DBA Trustic ("we" or "us") to perform the Services ("Services")
          described below. By providing your online bank account credentials, as
          described below, you are authorizing us to access, retrieve and store
          your account information maintained online by the bank where you have
          an account and that is accessible to you ("Account Information"),
          including the transaction history, so we can perform the Services.
          Access to your account(s) is enabled by a third-party called Plaid
          Technologies, Inc. ("Plaid"). By agreeing to this Agreement, you are
          also agreeing to Plaid's{" "}
          <a href="https://plaid.com/legal/" target="_blank">
            privacy policy
          </a>
          . You also agree that Plaid is an intended third-party beneficiary
          under this Agreement with respect to the Services.
          <br />
          <b>
            By agreeing to the Services, you agree to the terms set out below,
            including providing your online bank account credentials to us, and
            to our appointment to a limited power of attorney for purposes of
            accessing and retrieving your Account Information as part of the
            Services. Please read this document carefully. If you do not
            understand these terms, or do not accept any part of them, then you
            should not agree to the Services.
            <br />
            Account Credentials
          </b>
          <br />
          By submitting passwords, usernames, PINs, other log-in information,
          materials and other content to us (collectively, your "Account
          Credentials"), you are providing us a limited, personal, royalty-free
          license to your Account Credentials solely for the purpose of
          providing the Services as described below. We may use and, if
          necessary, store your Account Credentials, but only to perform the
          Services. Further, by submitting your Account Credentials to us, you
          represent that you are authorized to submit your Account Credentials
          to us for this purpose. We will use your Account Credentials to log
          you into your bank's website.
          <br />
          <b>
            Limited Power of Attorney to Access and Retrieve Your Account
            Information
          </b>
          <br />
          For purposes of providing the Services and solely to retrieve the
          Account Information as part of this Services, you grant us a limited
          power of attorney, and appoint us as your attorney-in-fact and agent,
          to access and retrieve the Account Information with the full power and
          authority to do and perform each thing necessary in connection with
          the Services, as you could do in person. YOU ACKNOWLEDGE AND AGREE
          THAT WHEN WE ACCESS AND RETRIEVE ACCOUNT INFORMATION FROM YOUR BANK,
          WE ARE ACTING AS YOUR AGENT, AND NOT AS THE AGENT OF OR ON BEHALF OF
          YOUR BANK. The Services are not endorsed nor sponsored by your bank.
          <br />
          <b>Services</b>
          <br />
          By agreeing to the Agreement, you expressly permit us to access,
          retrieve and store your Account Information in order to verify your
          identity and the accuracy of statements made in your online loan
          application (if applicable), perform credit underwriting, and offer
          other services that may be provided by, or through, Trustic. You
          further expressly permit us to access, retrieve, and store your
          Account Information for continuous use in transaction analysis for
          internal risk modeling, use in connection with the servicing of any
          loan you receive through Trustic, credit underwriting for future
          products or services, and marketing of products and services that may
          be offered by, or through, Trustic. The type of Account Information
          that we may access includes, but is not limited to, names and contact
          information associated with individuals on such accounts, income,
          employment, cash- flow, transaction data (including direct deposits),
          and debt obligations. You further agree that we may use your Account
          Credentials in order to deposit funds in your account or initiate
          electronic funds transfers upon your authorization.
          <br />
          WE MAKE NO WARRANTY THAT THE SERVICES AND THE ACCOUNT INFORMATION
          RETRIEVED AS PART OF THIS SERVICE, WILL BE ABLE TO VERIFY YOUR BANK
          ACCOUNT, THAT IT WILL BE TIMELY, SECURE OR ERROR-FREE, NOR THAT IT
          WILL BE ACCURATE OR RELIABLE. Please contact your bank if you believe
          any Account Information is incorrect.
          <br />
          To the extent that our Privacy Policy applies to any of your Account
          Information that we access, retrieve or store, you may review it{" "}
          <Link to="/privacy" target="_blank">
            here.
          </Link>
        </p>
      ),
    })
  }

  const linkBankAccount = async () => {
    setLinkBankLoading(true)
    setAppLoading(true)
    setAppLoadingStatus("Connecting your plaid account")

    const [res, status] = await REST({
      url: `${API_MAP.publicPaymentPlaidLinkToken}/${paymentId}`,
      method: "POST",
    })

    if (status === 200 && res?.data?.linkToken) {
      /* global Plaid */
      const handler = Plaid.create({
        token: res?.data?.linkToken,
        onSuccess: async (public_token, metadata) => {
          setAppLoading(true)
          setAppLoadingStatus("Connecting your plaid account")

          const [res, status] = await REST({
            url: API_MAP.publicPaymentPlaidLinkTokenUpdate,
            method: "POST",
            body: { plaidToken: public_token, paymentLinkToken: paymentId },
          })

          setAppLoadingStatus(null)
          setAppLoading(false)

          if (status === 200 && res?.data && res?.data.length) {
            setExistingBankAccounts(res.data)
          } else if (status === 200 && !res?.data) {
            setLinkBankLoading(false)
            Modal.mixin({
              customClass: {
                confirmButton: "btn btn-dark btn-xl fw-600 w-40",
                cancelButton: "btn btn-danger btn-xl fw-600 w-40",
              },
              buttonsStyling: false,
              backdrop: "#f1f1f1",
            }).fire({
              title: "<strong style='text-transform: uppercase'>Hmm..</strong>",
              text: res.message,
              confirmButtonText: "Ok",
            })
          }

          REST({
            url: `${API_MAP.sendPlaidEvent}`,
            method: "POST",
            body: {
              message: JSON.stringify({ public_token, metadata }),
            },
          })
        },
        onLoad: () => {
          setAppLoadingStatus(null)
          setAppLoading(false)
        },
        onExit: (err, metadata) => {
          setLinkBankLoading(false)

          REST({
            url: `${API_MAP.sendPlaidEvent}`,
            method: "POST",
            body: {
              message: JSON.stringify({ err, metadata }),
            },
          })
        },
        onEvent: (eventName, metadata) => {
          REST({
            url: `${API_MAP.sendPlaidEvent}`,
            method: "POST",
            body: {
              message: JSON.stringify({ eventName, metadata }),
            },
          })
        },
        receivedRedirectUri: null,
      })
      handler.open()
    }
  }

  useEffect(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    setTimeout(() => setAppLoading(false), 1000)
    if (paymentId) {
      checkPaymentLink()
    }
    if (!paymentId) {
      fetchPaymentDetails()
    }
  }, [])

  return (
    <>
      <header className="header pt-8 pb-4 mh-100vh">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mx-auto text-left">
              <StepHeader
                title="Make a payment"
                description={
                  <>
                    Use this feature to make a one-off payment on your loan.
                    Upon submitting your bank details, an ACH transaction will
                    be created. The funds will be taken from your bank account
                    the same day if the payment is processed before noon EST on
                    a business day. Otherwise, it will be taken the next
                    business day.
                  </>
                }
              />
              {!initialFormResults && (
                <Formik
                  initialValues={{
                    achConsent: false,
                    amount: "",
                    useExistingBankAccount: "",
                    consent: false,
                  }}
                  onSubmit={async (values, { setFieldError, resetForm }) => {
                    if (
                      values.useExistingBankAccount === true ||
                      values.useExistingBankAccount === "true"
                    ) {
                      const [res, status] = paymentId
                        ? await makePublicPayment(
                            {
                              achConsent: values.achConsent,
                              amount: values.amount,
                              saveAsPaymentMethod: values.saveAsPaymentMethod,
                              useExistingBankAccount:
                                values.useExistingBankAccount,
                              bankAccount: {},
                            },
                            paymentId
                          )
                        : await makePayment({
                            achConsent: values.achConsent,
                            amount: values.amount,
                            saveAsPaymentMethod: values.saveAsPaymentMethod,
                            useExistingBankAccount:
                              values.useExistingBankAccount,
                            bankAccount: {},
                          })

                      if (res && status === 200 && res?.data?.active) {
                        Modal.mixin({
                          customClass: {
                            confirmButton: "btn btn-dark btn-xl fw-600 w-40",
                            cancelButton: "btn btn-danger btn-xl fw-600 w-40",
                          },
                          buttonsStyling: false,
                          backdrop: "#f1f1f1",
                        })
                          .fire({
                            title:
                              "<strong style='text-transform: uppercase'>Success</strong>",
                            text:
                              res.message || "Payment successfully completed.",
                            confirmButtonText: "Ok",
                          })
                          .then(result => {
                            if (result.isConfirmed) {
                              document.body.scrollTop = 0
                              document.documentElement.scrollTop = 0
                              navigate(paymentId ? "/" : "/apply")
                            }
                          })
                      }

                      if (res && status === 200 && res.success) {
                        Modal.mixin({
                          customClass: {
                            confirmButton: "btn btn-dark btn-xl fw-600 w-40",
                            cancelButton: "btn btn-danger btn-xl fw-600 w-40",
                          },
                          buttonsStyling: false,
                          backdrop: "#f1f1f1",
                        })
                          .fire({
                            title:
                              "<strong style='text-transform: uppercase'>Success</strong>",
                            text:
                              res.message || "Payment successfully completed.",
                            confirmButtonText: "Ok",
                          })
                          .then(result => {
                            if (result.isConfirmed) {
                              document.body.scrollTop = 0
                              document.documentElement.scrollTop = 0
                              navigate(paymentId ? "/" : "/apply")
                            }
                          })
                      }

                      if (
                        res &&
                        status === 400 &&
                        res.message ===
                          "You should be owner of the bank account."
                      ) {
                        res.errors.map(({ element, message }) =>
                          setFieldError(element, message)
                        )

                        resetForm({
                          achConsent: false,
                          amount: "",
                          useExistingBankAccount: false,
                          consent: false,
                        })
                      }

                      if (
                        res &&
                        ((status === 200 && !res.data.active) ||
                          (status === 400 && res.message))
                      ) {
                        resetForm({
                          achConsent: false,
                          amount: "",
                          useExistingBankAccount: false,
                          consent: false,
                        })

                        Modal.mixin({
                          customClass: {
                            confirmButton: "btn btn-dark btn-xl fw-600 w-40",
                            cancelButton: "btn btn-danger btn-xl fw-600 w-40",
                          },
                          buttonsStyling: false,
                          backdrop: "#f1f1f1",
                        }).fire({
                          title:
                            "<strong style='text-transform: uppercase'>Hmm...</strong>",
                          text:
                            res.message ||
                            "Unfortunately we can accept only bank accounts with an address in Arizona. Please, enter a different bank account now.",
                          confirmButtonText: "Ok",
                        })
                      }
                    } else {
                      setInitialFormResults(values)
                    }
                  }}
                  validationSchema={InitialFormSchema}
                >
                  {formikProps => {
                    return (
                      <Form>
                        <div className="row no-gap mb-5">
                          <div className="col-md-12">
                            <div className="custom-controls-stacked">
                              <FormikField
                                type="currency"
                                prefix="$"
                                name="amount"
                                placeholder="Amount"
                              />
                              <FormikField
                                type="select"
                                name="useExistingBankAccount"
                              >
                                <option value="" disabled selected>
                                  Choose bank account to pay from
                                </option>
                                {existingBankAccount?.existingBankAccount && (
                                  <option value={true}>
                                    Bank Account{" "}
                                    {existingBankAccount?.existingBankAccount}
                                  </option>
                                )}
                                <option value={false}>
                                  Add new bank account
                                </option>
                              </FormikField>
                              <FormikField
                                type="checkbox"
                                name="consent"
                                textWith={100}
                                placeholder={
                                  <>
                                    I authorize Trustic to{" "}
                                    <a
                                      href="#"
                                      onClick={e => {
                                        e.preventDefault()
                                        setShowTermsModal(true)
                                        setTermsModalContent({
                                          title: "Bank Account Verification",
                                          hidePrint: true,
                                          lastUpdate: null,
                                          text: (
                                            <p className="lead-2">
                                              To verify your bank account,
                                              Trustic will initiate a debit and
                                              a credit for the same amount
                                              (under a dollar) against your bank
                                              account. When you see this
                                              activity post to your bank account
                                              (usually within 2-4 business days
                                              after you provide your bank
                                              account information), sign into
                                              Trustic, click on the "Verify your
                                              bank account" link in your Account
                                              Summary, and confirm the
                                              transaction amount.
                                            </p>
                                          ),
                                        })
                                      }}
                                    >
                                      verify your bank account
                                    </a>
                                    .
                                  </>
                                }
                              />
                              <FormikField
                                type="checkbox"
                                name="achConsent"
                                textWith={100}
                                placeholder={
                                  <a
                                    href="#"
                                    onClick={e => {
                                      e.preventDefault()
                                      setShowTermsModal("paymentAuthorization")
                                    }}
                                  >
                                    I consent to withdraw payments
                                    electronically.
                                  </a>
                                }
                              />
                            </div>
                          </div>
                          <div className="row col-8 offset-2">
                            <div className="col-12">
                              <button
                                type="submit"
                                className="btn btn-lg btn-dark btn-block"
                                disabled={!formikProps.isValid}
                              >
                                {stepLoading ? (
                                  <div
                                    className="loader"
                                    style={{
                                      borderColor: "rgb(255 255 255 / 20%)",
                                      borderLeftColor: "#fff",
                                      fontSize: "2.4px",
                                    }}
                                  />
                                ) : (
                                  "Submit"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              )}

              {!linkManually &&
                !existingBankAccounts &&
                !!initialFormResults?.useExistingBankAccount && (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <button
                          type="button"
                          className="btn btn-lg btn-dark btn-block"
                          onClick={e => {
                            e.stopPropagation()
                            linkBankAccount()
                          }}
                        >
                          {linkBankLoading ? (
                            <div
                              className="loader"
                              style={{
                                borderColor: "rgb(255 255 255 / 20%)",
                                borderLeftColor: "#fff",
                                fontSize: "2.4px",
                              }}
                            />
                          ) : (
                            "Link my Bank"
                          )}
                        </button>
                      </div>
                    </div>
                    <p className="my-5">
                      By linking your bank, you accept the{" "}
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          showBankLinkServicesAgreementInfo()
                        }}
                      >
                        Bank Link Services Agreement
                      </a>{" "}
                      and authorize use of your account for Services which
                      include future credit underwriting and analysis.
                    </p>
                    <div className="row">
                      <div className="col-12">
                        <button
                          type="button"
                          className="btn btn-lg btn-outline-dark btn-block px-1"
                          onClick={() =>
                            Modal.mixin({
                              customClass: {
                                confirmButton:
                                  "btn btn-dark btn-xl fw-600 w-90",
                                cancelButton:
                                  "btn btn-outline-dark btn-xl fw-600 w-90 d-block mt-3",
                              },
                              buttonsStyling: false,
                              backdrop: "#f1f1f1",
                            })
                              .fire({
                                title:
                                  "<strong style='text-transform: uppercase'>Are you sure you want to enter your bank details manually?</strong>",
                                text:
                                  "Your loan may be delayed 2-4 business days, and you may have to submit additional documentation. ",
                                confirmButtonText:
                                  "Yes, enter bank details manually",
                                cancelButtonText: "No, take me back",
                              })
                              .then(result => {
                                if (result.isConfirmed) {
                                  setLinkManually(true)
                                }
                              })
                          }
                        >
                          Manually enter bank details
                        </button>
                      </div>
                    </div>
                  </>
                )}

              {linkManually && !!initialFormResults?.useExistingBankAccount && (
                <Formik
                  initialValues={{
                    amount: initialFormResults?.amount,
                    saveAsPaymentMethod: null,
                    firstName: "",
                    lastName: "",
                    routingNumber: "",
                    bankName: "",
                    accountNumber: "",
                    confirmAccountNumber: "",
                    accountType: "",
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    state: "",
                    postalCode: "",
                  }}
                  onSubmit={async (values, { setFieldError, resetForm }) => {
                    const [res, status] = paymentId
                      ? await makePublicPayment(
                          {
                            amount: values.amount,
                            saveAsPaymentMethod: values.saveAsPaymentMethod,
                            useExistingBankAccount: false,
                            bankAccount: {
                              firstName: values.firstName,
                              lastName: values.lastName,
                              routingNumber: values.routingNumber,
                              bankName: values.bankName,
                              accountNumber: values.accountNumber,
                              confirmAccountNumber: values.confirmAccountNumber,
                              accountType: values.accountType,
                              addressLine1: values.addressLine1,
                              addressLine2: values.addressLine2,
                              city: values.city,
                              state: values.state,
                              postalCode: values.postalCode,
                            },
                          },
                          paymentId
                        )
                      : await makePayment({
                          amount: values.amount,
                          saveAsPaymentMethod: values.saveAsPaymentMethod,
                          useExistingBankAccount: false,
                          bankAccount: {
                            firstName: values.firstName,
                            lastName: values.lastName,
                            routingNumber: values.routingNumber,
                            bankName: values.bankName,
                            accountNumber: values.accountNumber,
                            confirmAccountNumber: values.confirmAccountNumber,
                            accountType: values.accountType,
                            addressLine1: values.addressLine1,
                            addressLine2: values.addressLine2,
                            city: values.city,
                            state: values.state,
                            postalCode: values.postalCode,
                          },
                        })

                    if (res && status === 200 && res?.data?.active) {
                      Modal.mixin({
                        customClass: {
                          confirmButton: "btn btn-dark btn-xl fw-600 w-40",
                          cancelButton: "btn btn-danger btn-xl fw-600 w-40",
                        },
                        buttonsStyling: false,
                        backdrop: "#f1f1f1",
                      })
                        .fire({
                          title:
                            "<strong style='text-transform: uppercase'>Success</strong>",
                          text:
                            res.message || "Payment successfully completed.",
                          confirmButtonText: "Ok",
                        })
                        .then(result => {
                          if (result.isConfirmed) {
                            document.body.scrollTop = 0
                            document.documentElement.scrollTop = 0
                            navigate(paymentId ? "/" : "/apply")
                          }
                        })
                    }

                    if (res && status === 200 && res.success) {
                      Modal.mixin({
                        customClass: {
                          confirmButton: "btn btn-dark btn-xl fw-600 w-40",
                          cancelButton: "btn btn-danger btn-xl fw-600 w-40",
                        },
                        buttonsStyling: false,
                        backdrop: "#f1f1f1",
                      })
                        .fire({
                          title:
                            "<strong style='text-transform: uppercase'>Success</strong>",
                          text:
                            res.message || "Payment successfully completed.",
                          confirmButtonText: "Ok",
                        })
                        .then(result => {
                          if (result.isConfirmed) {
                            document.body.scrollTop = 0
                            document.documentElement.scrollTop = 0
                            navigate(paymentId ? "/" : "/apply")
                          }
                        })
                    }

                    if (
                      res &&
                      status === 400 &&
                      res.message === "You should be owner of the bank account."
                    ) {
                      res.errors.map(({ element, message }) =>
                        setFieldError(element, message)
                      )

                      resetForm({
                        achConsent: false,
                        amount: "",
                        saveAsPaymentMethod: null,
                        useExistingBankAccount: false,
                        firstName: "",
                        lastName: "",
                        routingNumber: "",
                        bankName: "",
                        accountNumber: "",
                        confirmAccountNumber: "",
                        consent: false,
                        accountType: "",
                        addressLine1: "",
                        addressLine2: "",
                        city: "",
                        state: "",
                        postalCode: "",
                      })
                    }

                    if (
                      res &&
                      ((status === 200 && !res.data.active) ||
                        (status === 400 && res.message))
                    ) {
                      resetForm({
                        achConsent: false,
                        amount: "",
                        saveAsPaymentMethod: null,
                        useExistingBankAccount: false,
                        firstName: "",
                        lastName: "",
                        routingNumber: "",
                        bankName: "",
                        accountNumber: "",
                        confirmAccountNumber: "",
                        consent: false,
                        accountType: "",
                        addressLine1: "",
                        addressLine2: "",
                        city: "",
                        state: "",
                        postalCode: "",
                      })

                      Modal.mixin({
                        customClass: {
                          confirmButton: "btn btn-dark btn-xl fw-600 w-40",
                          cancelButton: "btn btn-danger btn-xl fw-600 w-40",
                        },
                        buttonsStyling: false,
                        backdrop: "#f1f1f1",
                      }).fire({
                        title:
                          "<strong style='text-transform: uppercase'>Hmm...</strong>",
                        text:
                          res.message ||
                          "Unfortunately we can accept only bank accounts with an address in Arizona. Please, enter a different bank account now.",
                        confirmButtonText: "Ok",
                      })
                    }
                  }}
                  validationSchema={RepaymentInfoSchema}
                >
                  {formikProps => {
                    return (
                      <Form>
                        <div className="row no-gap mb-5">
                          <div className="col-md-12">
                            <div className="custom-controls-stacked">
                              <FormikField
                                type="currency"
                                prefix="$"
                                name="amount"
                                placeholder="Amount"
                              />
                              <FormikField
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                              />
                              <FormikField
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                              />
                              <FormikField
                                type="text"
                                name="routingNumber"
                                placeholder="Routing Number"
                              />
                              <FormikField
                                type="text"
                                name="bankName"
                                placeholder="Bank Name"
                              />
                              <FormikField
                                type="text"
                                name="accountNumber"
                                placeholder="Account Number"
                              />
                              <FormikField
                                type="text"
                                name="confirmAccountNumber"
                                placeholder="Confirm Account Number"
                              />
                              <FormikField type="select" name="accountType">
                                <option value="" disabled selected>
                                  Please select Account Type
                                </option>
                                <option value={"CHECKING"}>Checking</option>
                                <option value={"SAVINGS"}>Savings</option>
                              </FormikField>
                              <FormikField
                                type="text"
                                name="addressLine1"
                                placeholder="Address Line 1"
                              />
                              <FormikField
                                type="text"
                                name="addressLine2"
                                placeholder="Address Line 2"
                              />
                              <FormikField
                                type="text"
                                name="city"
                                placeholder="City"
                              />
                              <FormikField type="select" name="state">
                                <option value="" disabled selected>
                                  Please select a State
                                </option>
                                {USSTATES.map(({ name, abbreviation }, key) => (
                                  <option value={abbreviation} key={key}>
                                    {name}
                                  </option>
                                ))}
                              </FormikField>
                              <FormikField
                                type="text"
                                name="postalCode"
                                placeholder="Zip Code"
                              />
                              <FormikField
                                type="select"
                                name="saveAsPaymentMethod"
                              >
                                <option value="" disabled selected>
                                  Update my repayment method with this bank
                                  account
                                </option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </FormikField>
                              {/* <FormikField
                                type="checkbox"
                                name="consent"
                                textWith={100}
                                placeholder={
                                  <>
                                    I authorize Trustic to{" "}
                                    <a
                                      href="#"
                                      onClick={e => {
                                        e.preventDefault()
                                        setShowTermsModal(true)
                                        setTermsModalContent({
                                          title: "Bank Account Verification",
                                          hidePrint: true,
                                          lastUpdate: null,
                                          text: (
                                            <p className="lead-2">
                                              To verify your bank account,
                                              Trustic will initiate a debit and
                                              a credit for the same amount
                                              (under a dollar) against your bank
                                              account. When you see this
                                              activity post to your bank account
                                              (usually within 2-4 business days
                                              after you provide your bank
                                              account information), sign into
                                              Trustic, click on the "Verify your
                                              bank account" link in your Account
                                              Summary, and confirm the
                                              transaction amount.
                                            </p>
                                          ),
                                        })
                                      }}
                                    >
                                      verify your bank account
                                    </a>
                                    .
                                  </>
                                }
                              />
                              <FormikField
                                type="checkbox"
                                name="achConsent"
                                textWith={100}
                                placeholder={
                                  <a
                                    href="#"
                                    onClick={e => {
                                      e.preventDefault()
                                      setShowTermsModal("paymentAuthorization")
                                    }}
                                  >
                                    I consent to withdraw payments
                                    electronically.
                                  </a>
                                }
                              /> */}
                            </div>
                          </div>
                          <div className="row col-8 offset-2">
                            <div className="col-2 px-0">
                              <button
                                type="button"
                                className="btn btn-lg btn-dark btn-block px-0"
                                onClick={e => {
                                  e.preventDefault()
                                  // window.history.back()
                                  setLinkManually(false)
                                }}
                              >
                                <span className="ti-angle-left"></span>
                              </button>
                            </div>
                            <div className="col-10">
                              <button
                                type="submit"
                                className="btn btn-lg btn-dark btn-block"
                                disabled={!formikProps.isValid}
                              >
                                {stepLoading ? (
                                  <div
                                    className="loader"
                                    style={{
                                      borderColor: "rgb(255 255 255 / 20%)",
                                      borderLeftColor: "#fff",
                                      fontSize: "2.4px",
                                    }}
                                  />
                                ) : (
                                  "Submit"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              )}

              {existingBankAccounts && (
                <Formik
                  initialValues={{
                    amount: initialFormResults?.amount,
                    saveAsPaymentMethod: null,
                    bankAccountId:
                      existingBankAccounts?.length > 1
                        ? null
                        : existingBankAccounts[0]?.id,
                  }}
                  onSubmit={async (values, { setFieldError, resetForm }) => {
                    const [res, status] = await makePlaidPayment(
                      {
                        amount: values.amount,
                        saveAsPaymentMethod: values.saveAsPaymentMethod,
                        bankAccountId: values.bankAccountId,
                      },
                      paymentId
                    )

                    if (res && status === 200 && res?.data?.active) {
                      Modal.mixin({
                        customClass: {
                          confirmButton: "btn btn-dark btn-xl fw-600 w-40",
                          cancelButton: "btn btn-danger btn-xl fw-600 w-40",
                        },
                        buttonsStyling: false,
                        backdrop: "#f1f1f1",
                      })
                        .fire({
                          title:
                            "<strong style='text-transform: uppercase'>Success</strong>",
                          text:
                            res.message || "Payment successfully completed.",
                          confirmButtonText: "Ok",
                        })
                        .then(result => {
                          if (result.isConfirmed) {
                            document.body.scrollTop = 0
                            document.documentElement.scrollTop = 0
                            navigate(paymentId ? "/" : "/apply")
                          }
                        })
                    }

                    if (res && status === 200 && res.success) {
                      Modal.mixin({
                        customClass: {
                          confirmButton: "btn btn-dark btn-xl fw-600 w-40",
                          cancelButton: "btn btn-danger btn-xl fw-600 w-40",
                        },
                        buttonsStyling: false,
                        backdrop: "#f1f1f1",
                      })
                        .fire({
                          title:
                            "<strong style='text-transform: uppercase'>Success</strong>",
                          text:
                            res.message || "Payment successfully completed.",
                          confirmButtonText: "Ok",
                        })
                        .then(result => {
                          if (result.isConfirmed) {
                            document.body.scrollTop = 0
                            document.documentElement.scrollTop = 0
                            navigate(paymentId ? "/" : "/apply")
                          }
                        })
                    }

                    if (
                      res &&
                      status === 400 &&
                      res.message === "You should be owner of the bank account."
                    ) {
                      res.errors.map(({ element, message }) =>
                        setFieldError(element, message)
                      )

                      resetForm({
                        achConsent: false,
                        amount: "",
                        saveAsPaymentMethod: null,
                        bankAccountId: null,
                      })
                    }

                    if (
                      res &&
                      ((status === 200 && !res.data.active) ||
                        (status === 400 && res.message))
                    ) {
                      resetForm({
                        achConsent: false,
                        amount: "",
                        saveAsPaymentMethod: null,
                        bankAccountId: null,
                      })

                      Modal.mixin({
                        customClass: {
                          confirmButton: "btn btn-dark btn-xl fw-600 w-40",
                          cancelButton: "btn btn-danger btn-xl fw-600 w-40",
                        },
                        buttonsStyling: false,
                        backdrop: "#f1f1f1",
                      }).fire({
                        title:
                          "<strong style='text-transform: uppercase'>Hmm...</strong>",
                        text:
                          res.message ||
                          "Unfortunately we can accept only bank accounts with an address in Arizona. Please, enter a different bank account now.",
                        confirmButtonText: "Ok",
                      })
                    }
                  }}
                  validationSchema={RepaymentInfoExistingAccountsSchema}
                  validateOnMount
                >
                  {formikProps => {
                    return (
                      <Form>
                        <div className="row no-gap mb-5">
                          <div className="col-md-12">
                            <div className="custom-controls-stacked">
                              <FormikField
                                type="listselect"
                                name="bankAccountId"
                                placeholder={
                                  existingBankAccounts?.length > 1
                                    ? "Select a bank account from the list below to make your payment."
                                    : null
                                }
                                values={existingBankAccounts}
                                renderComp={({
                                  accountNumber,
                                  bankName,
                                  firstName,
                                  lastName,
                                  routingNumber,
                                  availableBalance,
                                  id,
                                  onClick,
                                }) => (
                                  <li
                                    style={{
                                      border: "1px solid #eaeff4",
                                      cursor: "pointer",
                                      color:
                                        formikProps?.values?.bankAccountId ===
                                        id
                                          ? "#ffffff"
                                          : "#191919",
                                      backgroundColor:
                                        formikProps?.values?.bankAccountId ===
                                        id
                                          ? "#191919"
                                          : "",
                                    }}
                                    className="mt-3 p-3"
                                    onClick={e => {
                                      onClick(e)
                                      const selectedAccount = existingBankAccounts.filter(
                                        account => account.id === id
                                      )
                                      if (
                                        selectedAccount &&
                                        selectedAccount?.length &&
                                        selectedAccount[0]?.availableBalance <
                                          parseInt(formikProps?.values?.amount)
                                      ) {
                                        console.log(formikProps.setFieldError)
                                        formikProps.setFieldError(
                                          "amount",
                                          "The account balance is not sufficient to make this payment. Please, adjust the payment amount or choose a different bank account."
                                        )
                                        formikProps.setFieldTouched(
                                          "amount",
                                          true
                                        )
                                        // Modal.mixin({
                                        //   customClass: {
                                        //     confirmButton:
                                        //       "btn btn-dark btn-xl fw-600 w-40",
                                        //     cancelButton:
                                        //       "btn btn-danger btn-xl fw-600 w-40",
                                        //   },
                                        //   buttonsStyling: false,
                                        //   backdrop: "#f1f1f1",
                                        // }).fire({
                                        //   title:
                                        //     "<strong style='text-transform: uppercase'>Balance is lower than amount</strong>",
                                        //   text:
                                        //     "You can select a different account or adjust the amount",
                                        //   confirmButtonText: "Ok",
                                        // })
                                      }
                                    }}
                                  >
                                    <p className="mb-2">
                                      Bank Name: <b>{bankName}</b>
                                    </p>
                                    <p className="mb-2">
                                      Account Number: <b>{accountNumber}</b>
                                    </p>
                                    <p className="mb-0">
                                      Routing Number: <b>{routingNumber}</b>
                                    </p>
                                    {availableBalance && (
                                      <p className="mb-0">
                                        Balance: $
                                        <b>
                                          {availableBalance.toLocaleString(
                                            undefined,
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </b>
                                      </p>
                                    )}
                                  </li>
                                )}
                              />
                              <FormikField
                                type="currency"
                                prefix="$"
                                name="amount"
                                placeholder="Amount"
                              />
                              <FormikField
                                type="select"
                                name="saveAsPaymentMethod"
                              >
                                <option value="" disabled selected>
                                  Update my repayment method with this bank
                                  account
                                </option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </FormikField>
                            </div>
                          </div>
                          <div className="row col-8 offset-2">
                            <div className="col-2 px-0">
                              <button
                                type="button"
                                className="btn btn-lg btn-dark btn-block px-0"
                                onClick={e => {
                                  e.preventDefault()
                                  // window.history.back()
                                  setLinkManually(false)
                                  setExistingBankAccounts(null)
                                }}
                              >
                                <span className="ti-angle-left"></span>
                              </button>
                            </div>
                            <div className="col-10">
                              <button
                                type="submit"
                                className="btn btn-lg btn-dark btn-block"
                                disabled={!formikProps.isValid}
                              >
                                {stepLoading ? (
                                  <div
                                    className="loader"
                                    style={{
                                      borderColor: "rgb(255 255 255 / 20%)",
                                      borderLeftColor: "#fff",
                                      fontSize: "2.4px",
                                    }}
                                  />
                                ) : (
                                  "Submit"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </header>
      <AppTermsModal />
      <AppLoadingOverlay />
    </>
  )
}

const MakePaymentHOC = props => (
  <ApplyProvider>
    <MakePayment {...props} />
  </ApplyProvider>
)

export default MakePaymentHOC
