import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import MakePayment from "../components/payment/make-payment"
import Seo from "../components/seo"

const Apply = () => {
  return (
    <>
      <Layout app dark={true}>
        <Router basepath="/payment">
          <MakePayment path="/:paymentId" />
          <MakePayment path="/" />
        </Router>
      </Layout>
      <Seo
        title="Trustic | Payment"
        description="Apply for a guarantor personal loan of up to $3,000 now"
      />
    </>
  )
}
export default Apply
